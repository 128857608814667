.theme-light {
  --gray: #e8e8ea;
  --gray-light: #ddd;
  --white: #fff;
  --black: #222;
  --orange: #f5ab00;
  --red: #b40d01;
  --green: #01b578;
  --secondary-blue: #2e3192;
  --secondary-blue-light: #394494;
  --primary-blue: #c4dcf8;
  --primary-blue-light: #dfedfd;
  --yellow: #fff200;
  --border-color: #39449490;
}

.theme-light button {
  background: var(--secondary-blue);
  color: var(--white);
}

.theme-dark {
  --gray: #e8e8ea;
  --gray-light: #444;
  --white: #222;
  --black: #fff;
  --orange: #b78002;
  --red: #960e04;
  --green: #069565;
  --secondary-blue: #eee;
  --secondary-blue-light: #fff;
  --primary-blue: #3a4756;
  --primary-blue-light: #313c48;
  --yellow: #918c2e;
  --border-color: #77777750;
}

.theme-dark input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.theme-dark button {
  background: var(--primary-blue);
  color: var(--black);
}

/* * {
  --background-form: #ddd;
  --background-app: #fff;
  --color-primary: #2e3192;
  --color-secundary: #fff200;
  --color-primary-light: #394494;
  --color-text-in-primary: #fff;
  --color-text-black: #222;
  --background-header: #c4dcf8;
  --background-calendar: #fff;
  --background-header-calendar: #dfedfd;
  --background-hover-gray: #ddd;
  --background-item-today: #ccc;
  --background-mark-wait: #f5ab00;
  --background-mark-not-showed-up: #b40d01;
  --background-mark-confirmed: #01b478;
  --color-text-in-mark: #fff;
  --color-border-input: #2e3192;
} */

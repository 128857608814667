@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
.header-component {
  display: -webkit-flex;
  display: flex;
  height: 10%;
  width: 100%;
  color: var(--secondary-blue);
  background-color: var(--primary-blue);
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;

  position: relative;
}

.header-component .title {
  font-size: 1.8rem;
}

.header-component .button-logout {
  position: absolute;
  -webkit-align-self: center;
          align-self: center;
  right: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-component .button-logout button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: transparent;
  color: var(--secondary-blue);
  margin-left: 1rem;
  font-size: 0.9rem;
}

.header-component .button-logout button:hover {
  color: var(--secondary-blue-light);
}

@media (max-width: 1024px) {
  .header-component {
    height: 10rem;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .header-component .title {
    font-size: 1.4rem;
  }

  .header-component .button-logout {
    position: relative;
    right: 0;
  }
}

.date-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0.5rem;
  width: 100%;
}

.date-input .label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.date-input .input {
  border: 0.05rem solid var(--secondary-blue);
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
}

.date-input .input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.time-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0.5rem;
  width: 100%;
}

.time-input .label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.time-input .input {
  border: 0.05rem solid var(--secondary-blue);
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
}

.time-input .input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.modal.visible {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff90;
  transition-delay: all 1s linear;
  color: var(--black);
}

.modal .modal-visible {
  background-color: var(--white);
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: fixed;
  z-index: 10000;
}

.modal .modal-visible header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem;
}

.modal .modal-visible header .close-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 3rem;
  height: 3rem;
  background: none;
  color: var(--secondary-blue);
}

.modal .modal-visible header .close-modal .icon {
  font-size: 1.5rem;
}

.modal .modal-visible form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 0rem 2rem 0 2rem;
  margin-top: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.modal .modal-visible form .status {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.modal .modal-visible form .status .label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.modal .modal-visible form .status .input {
  border: 0.05rem solid var(--secondary-blue);
  width: 100%;
  height: 2.5rem;
  padding-left: 0.5rem;
  border-radius: 0.3rem;
}

.modal .modal-visible form .status .input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.modal .modal-visible form .text-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.modal .modal-visible form .time-input,
.modal .modal-visible form .date-input,
.modal .modal-visible form .text-input {
  padding: 0.4rem 0;
}

.modal .modal-visible form .text-input .label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.modal .modal-visible form .text-input .input,
.modal .modal-visible form .date-input .input,
.modal .modal-visible form .time-input .input {
  border: 0.05rem solid var(--secondary-blue);
  width: 100%;
  height: 2.5rem;
  padding-left: 0.5rem;
  border-radius: 0.3rem;
}

.modal .modal-visible form .text-input textarea {
  min-height: 5rem;
  resize: vertical;
  padding-top: 0.5rem;
}

.modal .modal-visible form .text-input input:focus,
.modal .modal-visible form .text-input textarea:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.modal .modal-visible form .button-box {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.modal .modal-visible form .button-box .button {
  width: 100%;
  height: 3rem;
  min-height: 3rem;
  cursor: pointer;
  transition: 200ms;
  margin: 1rem 0;
  border-radius: 0.3rem;
}

.modal .modal-visible form .button-box .button:hover {
  -webkit-filter: opacity(0.9);
          filter: opacity(0.9);
}

.modal .modal-visible form .button-box .button.secundary {
  background-color: var(--white);
  color: var(--secondary-blue);
  transition: 200ms;
}

.modal .modal-visible form .button-box .button.secundary:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

@media (min-width: 1000px) {
  .modal .modal-visible form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    grid-template-areas:
      "status status status status"
      "date-input date-input time-input time-input"
      "colaborator colaborator colaborator colaborator"
      "pacient pacient pacient numPacient"
      "valuation valuation tax tax"
      "phone phone address address"
      "responsable responsable responsable numResponsable"
      "observations observations observations observations"
      "button-box button-box button-box button-box";
  }

  .modal .modal-visible form .text-input.error {
    border: 1px solid #aa0000;
  }

  .modal .modal-visible form .status {
    grid-area: status;
  }

  .modal .modal-visible form .status .input {
    width: auto;
    width: initial;
  }

  .modal .modal-visible form .date-input {
    grid-area: date-input;
  }

  .modal .modal-visible form .time-input {
    grid-area: time-input;
  }

  .modal .modal-visible form .colaborator {
    grid-area: colaborator;
  }

  .modal .modal-visible form .pacient {
    grid-area: pacient;
  }

  .modal .modal-visible form .numPacient {
    grid-area: numPacient;
  }

  .modal .modal-visible form .valuation {
    grid-area: valuation;
  }

  .modal .modal-visible form .tax {
    grid-area: tax;
  }

  .modal .modal-visible form .phone {
    grid-area: phone;
  }

  .modal .modal-visible form .address {
    grid-area: address;
  }

  .modal .modal-visible form .responsable {
    grid-area: responsable;
  }

  .modal .modal-visible form .numResponsable {
    grid-area: numResponsable;
  }

  .modal .modal-visible form .observations {
    grid-area: observations;
  }

  .modal .modal-visible form .button-box {
    grid-area: button-box;
    margin-top: 1rem;
    width: auto;
  }
}

.loading-box {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #ffffff66;
}

.loading-box.hidden {
  display: none;
}

.loading-box .loading-circle {
  width: 100px;
  max-width: 100%;
  height: 100px;
  max-height: 100%;
  border: 5px solid var(--primary-blue);
  border-top: 5px solid var(--secondary-blue);
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media print {
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
    padding: 0;
  }

  .document {
    padding-left: 2.5vh;
    padding-right: 2.5vh;
  }

  .document tbody {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .document tbody .print-page:first-child {
    height: calc(100vh - 0.6mm);
  }

  .document tbody .print-page:first-child {
    height: calc(100vh - 1mm);
  }

  .print-page {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100vh;
  }

  .print-page .header-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .print-page .content-page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-bottom: 1rem;
  }

  .print-page .content-page .item-scheduling {
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 0.3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.modal-not-showed-up {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: #00000070;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  z-index: 1000;
}

.modal-not-showed-up .modal-active {
  width: 40rem;
  height: 100%;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;

  opacity: 1;

  background: var(--white);

  border-radius: 0.3rem;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  position: relative;
}

.modal-not-showed-up .modal-active .title {
  width: 100%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.modal-not-showed-up .modal-active .icon-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
}

.modal-not-showed-up .modal-active .select-period {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
}

.modal-not-showed-up .modal-active .select-period .select-title {
  margin-bottom: 0.4rem;
}

.modal-not-showed-up .modal-active .select-period .input-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal-not-showed-up .modal-active .select-period .input-box input {
  width: 48%;
  height: 2.5rem;
  background: var(--gray-light);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
}

.modal-not-showed-up .modal-active .select-period .button-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.5rem;
}

.modal-not-showed-up .modal-active .select-period .button-box .button-select {
  margin-top: 1rem;
  background: var(--primary-blue-light);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-not-showed-up .modal-active .select-period .button-box .clear-select {
  margin-top: 1rem;
  background: var(--white);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-not-showed-up .modal-active .select-period .clear-select:hover,
.modal-not-showed-up
  .modal-active
  .select-period
  .button-box
  .button-select:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-not-showed-up .modal-active .schedulings-not-showed-up {
  margin-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow-y: auto;
  margin-bottom: 3rem;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .numberOfSchedulings {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.modal-not-showed-up .modal-active .schedulings-not-showed-up .item-scheduling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border-radius: 0.2rem;
  border-bottom: 3px dotted var(--secondary-blue);
  padding-bottom: 1rem;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  + .item-scheduling {
  margin-top: 1rem;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text {
  display: -webkit-flex;
  display: flex;
  padding: 0.2rem;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  strong {
  margin-right: 1rem;
  width: 7rem;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  p {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-left: 1px dotted var(--secondary-blue);
  padding-left: 1rem;
  margin-right: 0;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  button {
  height: 2rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
  transition: 200ms;
}

.modal-not-showed-up
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  button:hover {
  -webkit-filter: opacity(0.9);
          filter: opacity(0.9);
}

.modal-not-showed-up .modal-active .print-button {
  width: 20rem;
  height: 2.5rem;
  margin: 0.5rem 0;

  font-size: 1.2rem;

  background-color: var(--primary-blue-light);
  color: var(--secondary-blue);
  border-radius: 0.2rem;

  position: fixed;
  bottom: 0;
  -webkit-align-self: center;
          align-self: center;
}

.modal-not-showed-up .modal-active .print-button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-not-showed-up .modal-active .print-button:disabled {
  background-color: var(--white);
  cursor: not-allowed;
}

.modal-report {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: #00000070;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  z-index: 1000;
}

.modal-report .modal-active {
  width: 40rem;
  height: 100%;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;

  opacity: 1;

  background: var(--white);

  border-radius: 0.3rem;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  position: relative;
}

.modal-report .modal-active .title {
  width: 100%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.modal-report .modal-active .icon-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
}

.modal-report .modal-active .select-period {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
}

.modal-report .modal-active .select-period .select-title {
  margin-bottom: 0.4rem;
}

.modal-report .modal-active .select-period .input-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal-report .modal-active .select-period .input-box input {
  width: 48%;
  height: 2.5rem;
  background: var(--gray-light);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
}

.modal-report .modal-active .select-period .button-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.5rem;
}

.modal-report .modal-active .select-period .button-box .button-select {
  margin-top: 1rem;
  background: var(--primary-blue-light);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-report .modal-active .select-period .button-box .clear-select {
  margin-top: 1rem;
  background: var(--white);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-report .modal-active .select-period .clear-select:hover,
.modal-report .modal-active .select-period .button-box .button-select:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-report .modal-active .select-status {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  width: 100%;
  height: 2.5rem;

  margin-top: 1rem;
}

.modal-report .modal-active .select-status .option {
  width: 24%;
  border-radius: 0.3rem;
  padding: 0.3rem;
  height: 100%;
  background: transparent;
  color: var(--secondary-blue);
  font-size: 0.7rem;
  transition: 150ms;
}

.modal-report .modal-active .select-status .option.selected {
  background: var(--primary-blue-light);
  color: var(--secondary-blue);
}

.modal-report .modal-active .select-status .option:hover {
  -webkit-filter: opacity(0.85);
          filter: opacity(0.85);
}

.modal-report .modal-active .schedulings-not-showed-up {
  margin-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow-y: auto;
  margin-bottom: 3rem;
}

.modal-report .modal-active .schedulings-not-showed-up .numberOfSchedulings {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.modal-report .modal-active .schedulings-not-showed-up .item-scheduling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border-radius: 0.2rem;
  border-bottom: 3px dotted var(--secondary-blue);
  padding-bottom: 1rem;
}

.modal-report
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  + .item-scheduling {
  margin-top: 1rem;
}

.modal-report
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text {
  display: -webkit-flex;
  display: flex;
  padding: 0.2rem;
}

.modal-report
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  strong {
  margin-right: 1rem;
  width: 7rem;
}

.modal-report
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  p {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-left: 1px dotted var(--secondary-blue);
  padding-left: 1rem;
  margin-right: 0;
}

.modal-report .modal-active .schedulings-not-showed-up .item-scheduling button {
  height: 2rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
  transition: 200ms;
}

.modal-report
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  button:hover {
  -webkit-filter: opacity(0.9);
          filter: opacity(0.9);
}

.modal-report .modal-active .print-button {
  width: 20rem;
  height: 2.5rem;
  margin: 0.5rem 0;

  font-size: 1.2rem;

  background-color: var(--primary-blue-light);
  color: var(--secondary-blue);

  border-radius: 0.2rem;

  position: fixed;
  bottom: 0;
  -webkit-align-self: center;
          align-self: center;
}

.modal-report .modal-active .print-button:not(:disabled):hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-report .modal-active .print-button:disabled {
  background-color: var(--white);
  cursor: not-allowed;
}

.modal-canceled {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: #00000070;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  z-index: 1000;
}

.modal-canceled .modal-active {
  width: 40rem;
  height: 100%;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;

  opacity: 1;

  background: var(--white);

  border-radius: 0.3rem;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  position: relative;
}

.modal-canceled .modal-active .title {
  width: 100%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.modal-canceled .modal-active .icon-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
}

.modal-canceled .modal-active .select-period {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
}

.modal-canceled .modal-active .select-period .select-title {
  margin-bottom: 0.4rem;
}

.modal-canceled .modal-active .select-period .input-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal-canceled .modal-active .select-period .input-box input {
  width: 48%;
  height: 2.5rem;
  background: var(--gray-light);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
}

.modal-canceled .modal-active .select-period .button-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.5rem;
}

.modal-canceled .modal-active .select-period .button-box .button-select {
  margin-top: 1rem;
  background: var(--primary-blue-light);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-canceled .modal-active .select-period .button-box .clear-select {
  margin-top: 1rem;
  background: var(--white);
  color: var(--secondary-blue-light);
  border-radius: 0.3rem;
  width: 48%;
  height: 2.5rem;
}

.modal-canceled .modal-active .select-period .clear-select:hover,
.modal-canceled .modal-active .select-period .button-box .button-select:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-canceled .modal-active .schedulings-not-showed-up {
  margin-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow-y: auto;
  margin-bottom: 3rem;
}

.modal-canceled .modal-active .schedulings-not-showed-up .numberOfSchedulings {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.modal-canceled .modal-active .schedulings-not-showed-up .item-scheduling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border-radius: 0.2rem;
  border-bottom: 3px dotted var(--secondary-blue);
  padding-bottom: 1rem;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  + .item-scheduling {
  margin-top: 1rem;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text {
  display: -webkit-flex;
  display: flex;
  padding: 0.2rem;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  strong {
  margin-right: 1rem;
  width: 7rem;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  .box-text
  p {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-left: 1px dotted var(--secondary-blue);
  padding-left: 1rem;
  margin-right: 0;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  button {
  height: 2rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
  transition: 200ms;
}

.modal-canceled
  .modal-active
  .schedulings-not-showed-up
  .item-scheduling
  button:hover {
  -webkit-filter: opacity(0.9);
          filter: opacity(0.9);
}

.modal-canceled .modal-active .print-button {
  width: 20rem;
  height: 2.5rem;
  margin: 0.5rem 0;

  font-size: 1.2rem;

  background-color: var(--primary-blue-light);
  color: var(--secondary-blue);
  border-radius: 0.2rem;

  position: fixed;
  bottom: 0;
  -webkit-align-self: center;
          align-self: center;
}

.modal-canceled .modal-active .print-button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.modal-canceled .modal-active .print-button:disabled {
  background-color: var(--white);
  cursor: not-allowed;
}

.home-page {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black);
}

.home-page .icons-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  position: absolute;
  left: 1rem;

  cursor: pointer;
}

.home-page .icons-header .icon {
  font-size: 1.6rem;
  font-size: 1.5rem;
}

.home-page .icons-header .icon + .icon {
  margin-left: 0.5rem;
}

.home-page main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 90%;
  overflow: hidden;
}

.home-page main .header-calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  min-height: 85px;
  background: var(--primary-blue-light);
  border-bottom: 0.01rem solid var(--border-color);
  min-height: 2.5rem;
}

.home-page main .header-calendar .item-actions {
  color: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.home-page main .header-calendar .item-actions button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: var(--secondary-blue-light);
  background: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.3rem;
}

.home-page main .header-calendar .item-actions button:hover {
  color: var(--secondary-blue);
}

.home-page main .header-calendar .item-day {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  height: 100%;
  -webkit-flex: 1.2 1;
          flex: 1.2 1;
  color: var(--secondary-blue);
}

.home-page main .header-calendar .item-day.today {
  background: var(--yellow);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: var(--secondary-blue);
}

.home-page main .header-calendar .item-day.today .name-doctor-day {
  background-color: var(--yellow);
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.home-page main .header-calendar .item-day .name-doctor-day {
  width: 100%;
  min-height: 1.5rem;
  height: 1.5rem;

  border-radius: 3px;

  background-color: var(--primary-blue-light);
  color: var(--secondary-blue);

  margin-top: 0.3rem;
  padding-top: 0.1rem;

  overflow: hidden;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.home-page main .header-calendar .item-day .name-doctor-day.no-doctor {
  color: var(--secondary-blue-light);
}

.home-page main .header-calendar .scroll-align {
  width: 8px;
  background: transparent;
  height: 100%;
}

.home-page main .calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  background: var(--white);
  overflow: auto;
}

.home-page main .calendar .times-calendar,
.home-page main .calendar .days-calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1.2 1;
          flex: 1.2 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.home-page main .calendar .times-calendar {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 112px;
}

.home-page main .calendar .times-calendar div {
  display: -webkit-flex;
  display: flex;
  height: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  background: var(--primary-blue-light);
  border-bottom: 0.01rem dotted var(--border-color);
  color: var(--secondary-blue);
}

.home-page main .calendar .days-calendar div,
.home-page main .calendar .days-calendar .button-calendar {
  height: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px dotted var(--border-color);
  border-left: 1px dotted var(--border-color);
}

.home-page main .calendar .days-calendar .button-calendar {
  background: var(--white);
  padding: 3px;
}

.home-page main .calendar .days-calendar .button-calendar.block {
  cursor: not-allowed;
  background: var(--gray-light);
}

.home-page main .calendar .days-calendar .button-calendar .pacient-name {
  -webkit-align-self: center;
          align-self: center;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 0;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */

  color: var(--gray);
}

.home-page
  main
  .calendar
  .days-calendar
  .button-calendar.confirmed
  .pacient-name {
  background: var(--green);
}

.home-page main .calendar .days-calendar .button-calendar.wait .pacient-name {
  background: var(--orange);
}

.home-page
  main
  .calendar
  .days-calendar
  .button-calendar.not-showed-up
  .pacient-name {
  background: var(--red);
}

.home-page main .calendar .days-calendar .button-calendar .pacient-name:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

/* Toast de alerta */

.home-page .toast {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;

  position: absolute;

  z-index: 2;

  bottom: 2rem;

  -webkit-align-self: center;

          align-self: center;

  opacity: 1;

  transition: 300ms;
}

.home-page .toast .toast-1,
.home-page .toast .toast-2 {
  width: 100%;

  color: #fff;
  background: #000000aa;

  padding: 1rem;
  opacity: 1;
  border-radius: 0.3rem;
  transition: 300ms;
}

.home-page .toast .toast-1.hidden,
.home-page .toast .toast-2.hidden {
  opacity: 0;
}

.home-page .toast .toast-2 {
  margin-top: 0.5rem;
  display: -webkit-flex;
  display: flex;
  font-size: 1.5rem;
  -webkit-align-items: center;
          align-items: center;
}

.home-page .toast p {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 1rem;
}

.home-page .toast .button-toast {
  margin-left: 1rem;
  cursor: pointer;
}

.home-page .modal-edit-doctor {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: #00000070;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  z-index: 1000;
}

.home-page .modal-edit-doctor .modal-active {
  width: 30rem;
  max-width: 90%;
  padding: 1.2rem;

  opacity: 1;

  background: var(--white);

  border-radius: 0.3rem;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-page .modal-edit-doctor .modal-active input {
  width: 100%;
  height: 2rem;
  border: 1px solid var(--secondary-blue);
  padding-left: 0.7rem;
}

.home-page .modal-edit-doctor .modal-active .modal-box-button {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 1rem;
}

.home-page .modal-edit-doctor .modal-active .modal-box-button button {
  width: 10rem;
  height: 2rem;
  border-radius: 3px;
  background-color: var(--white);
  color: var(--secondary-blue);
}

.home-page .modal-edit-doctor .modal-active .modal-box-button button + button {
  margin-left: 0.5rem;
  background-color: var(--secondary-blue);
  color: var(--white);
}

@media (max-width: 900px) {
  .home-page main .header-calendar * {
    font-size: 90%;
  }

  .home-page main .calendar * {
    font-size: 95%;
  }

  .home-page main .calendar > div {
    -webkit-flex: 1 1;
            flex: 1 1;
  }
}

@media (max-width: 550px) {
  .home-page .icons-header .icon + .icon {
    margin-left: 1.5rem;
  }

  .home-page main .header-calendar * {
    font-size: 85%;
  }

  .home-page main .header-calendar .item-actions,
  .home-page main .calendar .times-calendar {
    min-width: 140px;
  }

  .home-page main .header-calendar .item-actions > button {
    width: 4rem;
    height: 4rem;
    font-size: 1.7rem;
  }

  .home-page main .header-calendar .item-day,
  .home-page main .calendar .days-calendar {
    -webkit-flex: 2.5 1;
            flex: 2.5 1;
  }

  .home-page main .header-calendar .item-day .name-doctor-day {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .home-page main .header-calendar .scroll-align {
    -webkit-flex: 0 1;
            flex: 0 1;
  }

  .home-page main .calendar * {
    font-size: 90%;
  }

  .home-page main .calendar > div {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .home-page .toast {
    bottom: 4rem;
  }

  .home-page .icons-header {
    bottom: 0.5rem;
    left: 0;
    right: 0;
  }
}

@media (max-width: 450px) {
  .home-page main .header-calendar .name-doctor-day {
    font-size: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .home-page .toast * {
    font-size: 80%;
  }
}

.admin-page {
  height: 100vh;
  width: 100%;
  background: var(--primary-blue-light);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black);
}

.admin-page .top {
  background-color: var(--secondary-blue);
  width: 100%;
  height: 50%;
}

.admin-page main {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: calc(100vh - 5rem);
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.admin-page main button {
  width: 30%;
  height: 20rem;
  border-radius: 0.3rem;
  background: var(--primary-blue);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
  transition: 200ms;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary-blue);
}

.admin-page main button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

@media (max-width: 700px) {
  .admin-page main {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .admin-page main button {
    width: 70%;
    height: 20%;
  }
}

.login-page {
  height: 100vh;
  width: 100%;
  background: var(--primary-blue);
  color: var(--black);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.login-page form {
  position: absolute;
  -webkit-align-self: center;
          align-self: center;
  width: 40rem;
  height: 30rem;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3rem;
  background: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.login-page form h2 {
  margin-bottom: 3rem;
}

.login-page form label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.login-page form .input {
  padding-left: 0.5rem;
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  background: var(--white);
  color: var(--black);
  border: 0.05rem solid var(--secondary-blue-light);
}

.login-page form .input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.login-page form .input + label {
  margin-top: 2rem;
}

.login-page form .button-submit {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 15rem;
  height: 3rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
}

.login-page form .button-submit:hover {
  opacity: 0.9;
}

.login-page form .button-submit.loading {
  background-color: transparent;
}

.alter-page {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-blue-light);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black);
}

.alter-page .top {
  background-color: var(--secondary-blue);
  width: 100%;
  height: 50%;
}

.alter-page form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 2rem;
  width: 25rem;
  height: 30rem;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3rem;
  background: var(--white);
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
}

.alter-page form h2 {
  margin-bottom: 3rem;
}

.alter-page form label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.alter-page form input {
  padding-left: 0.5rem;
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 0.05rem solid var(--secondary-blue);
  background: transparent;
}

.alter-page form input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.alter-page form input + label {
  margin-top: 2rem;
}

.alter-page form .submit {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
}

.alter-page form .submit:hover {
  opacity: 0.9;
  color: var(--yellow);
}

.alter-page form .cancel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: transparent;
  color: var(--secondary-blue);
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.alter-page form .cancel:hover {
  color: var(--secondary-blue-light);
}

.register-page {
  height: 100vh;
  width: 100%;
  background: var(--primary-blue-light);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black);
}

.register-page .top {
  background-color: var(--secondary-blue);
  width: 100%;
  height: 50%;
}

.register-page form {
  -webkit-align-self: center;
          align-self: center;
  margin-top: 2rem;
  width: 25rem;
  max-width: 100%;
  height: 30rem;
  max-height: 100%;
  border-radius: 0.3rem;
  background: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
}

.register-page form h2 {
  margin-bottom: 2.5rem;
}

.register-page form label {
  margin-bottom: 0.3rem;
  font-size: 1rem;
}

.register-page form input {
  padding-left: 0.5rem;
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 0.05rem solid var(--secondary-blue);
  background: transparent;
}

.register-page form input:focus {
  border: 0.1rem solid var(--secondary-blue);
}

.register-page form input + label {
  margin-top: 1.5rem;
}

.register-page form .submit {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
}

.register-page form .submit:hover {
  opacity: 0.9;
}

.register-page form .cancel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: transparent;
  color: var(--secondary-blue);
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.register-page form .cancel:hover {
  color: var(--secondary-blue-light);
}

.list-schedulers-page {
  height: 100vh;
  width: 100%;
  background-color: var(--primary-blue-light);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--black);
}

.list-schedulers-page .top {
  background-color: var(--secondary-blue);
  width: 100%;
  height: 50%;
}

.list-schedulers-page main {
  -webkit-align-self: center;
          align-self: center;
  width: 25rem;
  max-width: 100%;
  height: 30rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  background: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 2rem;
}

.list-schedulers-page main header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-bottom: 3rem;
}

.list-schedulers-page main header .icon {
  cursor: pointer;
}

.list-schedulers-page main label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.list-schedulers-page .list-schedulers {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.3rem;
  overflow: auto;
}

.list-schedulers-page .list-schedulers .item-list {
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.presentation-page {
  height: 100vh;
  width: 100%;
  background: var(--primary-blue);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.presentation-page main {
  width: 90%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: var(--white);
  border-radius: 0.3rem;
}

.presentation-page main .actions {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.presentation-page main .actions h2 {
  font-size: 2.5rem;
  margin-bottom: 5rem;
  font-size: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #303b92;
  color: #fff;
  height: 150px;
  border-radius: 0.5rem;
}

.presentation-page main .actions h2 .title-logo {
  height: 100px;
}

.presentation-page main .actions button {
  width: 296.17px;
  height: 3rem;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.presentation-page main .actions button .icon {
  margin-left: 1rem;
}

.presentation-page main .actions button:hover {
  opacity: 0.9;
}

@media (max-width: 1000px) {
  .presentation-page main {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 40rem;
    max-width: 100%;
  }

  .presentation-page main .actions {
    height: 50%;
    width: 100%;
  }

  .presentation-page main .actions h2 {
    margin-bottom: 1rem;
  }

  .presentation-page main .actions h2 .title-logo {
    max-width: 100%;
  }

  .presentation-page main .actions button {
    max-width: 100%;
  }
}

.home-admin {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-admin main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 90%;
  overflow: hidden;
  margin-bottom: 3rem;
  background: var(--white);
  color: var(--black);
}

.home-admin main .header-calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  background: var(--primary-blue-light);
  border-bottom: 0.01rem solid var(--secondary-blue);
  min-height: 2.5rem;
}

.home-admin main .header-calendar-rules {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  background: var(--primary-blue-light);
  min-height: 2.5rem;
}

.home-admin main .header-calendar .item-actions,
.home-admin main .header-calendar-rules .item-actions {
  color: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.home-admin main .header-calendar .item-actions button,
.home-admin main .header-calendar-rules .item-actions button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: var(--secondary-blue-light);
  background: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.3rem;
}

.home-admin main .header-calendar .item-actions button:hover,
.home-admin main .header-calendar-rules .item-actions button:hover {
  color: var(--secondary-blue);
}

.home-admin main .header-calendar .item-day,
.home-admin main .header-calendar-rules .item-day {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  height: 100%;
  -webkit-flex: 1.2 1;
          flex: 1.2 1;
  color: var(--secondary-blue);
}

.home-admin main .header-calendar-rules .item-day {
  cursor: pointer;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.home-admin main .header-calendar .item-day.today,
.home-admin main .header-calendar-rules .item-day.today {
  background: var(--yellow);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: var(--secondary-blue);
}

.home-admin main .header-calendar .item-day.today .name-doctor-day {
  background-color: var(--yellow);
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.home-admin main .header-calendar .item-day .name-doctor-day,
.home-admin main .header-calendar-rules .item-day .name-doctor-day {
  width: 100%;
  min-height: 1.5rem;
  height: 1.5rem;

  background-color: var(--primary-blue-light);
  color: var(--secondary-blue);

  margin-top: 0.3rem;
  padding-top: 0.1rem;

  overflow: hidden;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.home-admin main .header-calendar .item-day .name-doctor-day.no-doctor,
.home-admin main .header-calendar-rules .item-day .name-doctor-day.no-doctor {
  color: var(--secondary-blue-light);
}

.home-admin main .header-calendar .scroll-align,
.home-admin main .header-calendar-rules .scroll-align {
  width: 8px;
  background: transparent;
  height: 100%;
}

.home-admin main .switch-mode {
  width: 100%;
  padding-left: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-admin main .switch-mode .subtitle {
  margin-bottom: 0.5rem;
  margin-top: 0.7rem;
  font-size: 1.2rem;
}

.home-admin main .switch-mode .input-time-custom {
  margin-bottom: 0.5rem;
  display: -webkit-flex;
  display: flex;
}

.home-admin main .switch-mode .input-time-custom .select-box,
.home-admin main .switch-mode .input-time-custom .button-box {
  display: -webkit-flex;
  display: flex;
}

.home-admin main .switch-mode .input-time-custom .button-box {
  margin-left: 1rem;
}

.home-admin main .switch-mode .input-time-custom select {
  width: 10rem;
  height: 1.8rem;
  border: 1px solid var(--black);
  border-radius: 3px;
}

.home-admin main .switch-mode .input-time-custom p {
  margin: 0 0.3rem;
}

.home-admin main .switch-mode .input-time-custom button {
  height: 1.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border-radius: 3px;
  background: var(--primary-blue-light);
  color: var(--secondary-blue);
  transition: 200ms;
}

.home-admin main .switch-mode .input-time-custom button + button {
  margin-left: 0.5rem;
}

.home-admin main .switch-mode .input-time-custom button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.home-admin main .switch-mode .input-time-custom button .icon {
  margin-right: 0.3rem;
}

.home-admin main .switch-mode .box-time-interval {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.5rem;
}

.home-admin main .switch-mode .box-time-interval .input-time-interval {
  width: 4rem;
  padding: 0.4rem;
  height: 1.8rem;
  border: 1px solid var(--black);
  border-radius: 3px;
}

.home-admin main .switch-mode .box-time-interval button {
  width: 5rem;
  height: 1.8rem;
  margin-left: 1rem;
  border-radius: 3px;
}

.home-admin main .calendar,
.home-admin main .calendar-rules {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  background: var(--white);
  overflow: auto;
}

.home-admin main .calendar-rules {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  background: transparent;
}

.home-admin main .calendar .times-calendar,
.home-admin main .calendar .days-calendar,
.home-admin main .calendar-rules .days-calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1.2 1;
          flex: 1.2 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.home-admin main .calendar .times-calendar {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 112px;
}

.home-admin main .calendar-rules .days-calendar {
  padding: 0 1rem;
}

.home-admin main .calendar .days-calendar .group-button-calendar,
.home-admin main .calendar-rules .days-calendar .group-button-calendar {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.home-admin main .calendar .times-calendar div {
  display: -webkit-flex;
  display: flex;
  height: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  background: var(--primary-blue-light);
  border-bottom: 1px dotted var(--border-color);
  color: var(--secondary-blue);
}

.home-admin main .calendar .days-calendar div,
.home-admin main .calendar .days-calendar .button-calendar {
  height: 3rem;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: var(--white);
  border-bottom: 1px dotted var(--border-color);
  border-left: 1px dotted var(--border-color);
  cursor: auto;
  cursor: initial;
  padding: 3px;
}

.home-admin main .calendar-rules .days-calendar .button-calendar {
  height: 1.5rem;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  background: var(--white);
  color: var(--secondary-blue-light);

  border: 1px dotted var(--border-color);
  border-radius: 3px;

  cursor: auto;

  cursor: initial;
  transition: 150ms;
}

.home-admin main .calendar-rules .days-calendar .button-calendar:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.home-admin main .calendar .days-calendar .button-calendar.block {
  cursor: not-allowed;
  background: var(--gray-light);
}

.home-admin main .calendar-rules .days-calendar .button-calendar.selected {
  background: var(--primary-blue-light);
  color: var(--secondary-blue);
  font-weight: bold;
}

.home-admin main .calendar .days-calendar .button-calendar .pacient-name {
  -webkit-align-self: center;
          align-self: center;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 0;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */

  color: var(--gray);
}

.home-admin
  main
  .calendar
  .days-calendar
  .button-calendar.confirmed
  .pacient-name {
  background: var(--green);
}

.home-admin main .calendar .days-calendar .button-calendar.wait .pacient-name {
  background: var(--orange);
}

.home-admin
  main
  .calendar
  .days-calendar
  .button-calendar.not-showed-up
  .pacient-name {
  background: var(--red);
}

.home-admin main .calendar .days-calendar .button-calendar .pacient-name:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.home-admin main .box-button {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem;
}

.home-admin main .legend {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 1.5rem;
}

.home-admin main .legend .legend-service-day,
.home-admin main .legend .legend-no-service-day {
  width: 1rem;
  height: 1rem;
  background: var(--primary-blue);
  border: 1px dotted var(--border-color);
  border-radius: 3px;
  margin-right: 0.7rem;
  margin-left: 0.5rem;
}

.home-admin main .legend .legend-no-service-day {
  background: var(--white);
}

.home-admin main .box-button button {
  width: 10rem;
  height: 2rem;
  border-radius: 0.1rem;
  background: var(--primary-blue);
  color: var(--secondary-blue);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1rem;
}

.home-admin main .box-button button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.home-admin main .box-button button.block {
  background: var(--white);
  color: var(--white);
  cursor: not-allowed;
}

.home-admin main .box-button button .icon-save {
  margin-left: 0.5rem;
}

.home-admin footer {
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  bottom: 0;
}

.home-admin footer button {
  width: 50%;
  height: 100%;
  background: var(--primary-blue-light);
  color: var(--secondary-blue-light);
  transition: 200ms;
  font-size: 1.1rem;
  font-weight: normal;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.home-admin footer button.highlight {
  font-weight: bold;
}

.home-admin footer button:hover {
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}

.home-admin footer button p {
  margin-left: 1rem;
}

/* Modal Scheduling */

.home-admin .modal-scheduling {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: #00000070;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  z-index: 1000;
}

.home-admin .modal-scheduling.hidden {
  display: none;
  opacity: 0;
}

.home-admin .modal-scheduling .modal-active {
  width: 40rem;
  max-width: 100%;
  padding: 1.5rem;

  opacity: 1;

  background: var(--white);
  color: var(--black);

  border-radius: 0.3rem;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-admin .modal-scheduling .modal-active .title {
  width: 100%;
  height: 2rem;
  position: relative;
  color: var(--secondary-blue);
  margin-bottom: 0.5rem;
}

.home-admin .modal-scheduling .modal-active .title .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: 200ms;
}

.home-admin .modal-scheduling .modal-active .title .icon-close:hover {
  -webkit-filter: opacity(0.8);
          filter: opacity(0.8);
}

.home-admin .modal-scheduling .modal-active .grid-fields {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-admin .modal-scheduling .modal-active .grid-fields .field {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.home-admin .modal-scheduling .modal-active .grid-fields .field p {
  font-weight: bold;
  width: 10rem;
  min-height: 1.5rem;
  margin: 0.2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.home-admin .modal-scheduling .modal-active .grid-fields .field p + p {
  font-weight: normal;
  -webkit-flex: 1 1;
          flex: 1 1;
  height: auto;
  border-left: 1px dotted var(--secondary-blue);
  padding-left: 1rem;
}

.home-admin .modal-scheduling .modal-active .grid-fields .field .circle-status {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--black);
  margin-right: 0.5rem;
}

.home-admin
  .modal-scheduling
  .modal-active
  .grid-fields
  .field
  .circle-status.confirmed {
  background: var(--green);
}

.home-admin
  .modal-scheduling
  .modal-active
  .grid-fields
  .field
  .circle-status.wait {
  background: var(--orange);
}

.home-admin
  .modal-scheduling
  .modal-active
  .grid-fields
  .field
  .circle-status.not-showed-up {
  background: var(--red);
}

@media (max-width: 900px) {
  .home-admin main .header-calendar * {
    font-size: 90%;
  }

  .home-admin main .calendar * {
    font-size: 95%;
  }

  .home-admin main .calendar > div {
    -webkit-flex: 1 1;
            flex: 1 1;
  }
}

@media (max-width: 750px) {
  .home-admin main .header-calendar .item-actions {
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 80px;
  }

  .home-admin main .calendar .times-calendar {
    min-width: 80px;
  }

  .home-admin main .calendar-rules {
    padding: 0.5rem;
  }

  .home-admin main .calendar-rules .days-calendar * {
    font-size: 80%;
  }

  .home-admin main .switch-mode {
    padding: 0 0.5rem;
  }

  .home-admin main .switch-mode .input-time-custom {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0.5rem;
  }

  .home-admin main .switch-mode .input-time-custom .button-box {
    margin-left: 0;
    margin-top: 0.5rem;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 23rem;
  }

  .home-admin main .switch-mode .input-time-custom .button-box button {
    width: 48%;
  }

  .home-admin main .switch-mode .input-time-custom .select-box {
    width: 23rem;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .home-admin main .switch-mode .input-time-custom .select-box select {
    font-size: 90%;
    border-radius: 0.3rem;
  }

  .home-admin main .switch-mode .subtitle {
    margin: 0.3rem 0 0.3rem 0;
  }
}

@media (max-width: 550px) {
  .home-admin main .header-calendar * {
    font-size: 85%;
  }

  .home-admin main .calendar * {
    font-size: 90%;
  }

  .home-admin main .calendar > div {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .home-admin main .box-button .legend {
    font-size: 60%;
  }

  .home-admin .toast {
    bottom: 0;
  }

  .home-admin .modal-scheduling .modal-active .grid-fields .field p {
    font-size: 90%;
  }
}

@media (max-width: 450px) {
  .home-admin main .header-calendar * {
    font-size: 75%;
  }

  .home-admin main .switch-mode .input-time-custom .select-box,
  .home-admin main .switch-mode .input-time-custom .button-box {
    width: 100%;
  }

  .home-admin main .switch-mode .input-time-custom .button-box button {
    font-size: 70%;
  }

  .home-admin main .header-calendar .name-doctor-day {
    font-size: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .home-admin main .calendar * {
    font-size: 80%;
  }

  .home-admin .modal-scheduling .modal-active .grid-fields .field p {
    font-size: 85%;
  }
}

.toggle-theme {
  position: fixed !important;
  bottom: 1rem;
  right: 1rem;
  z-index: 9;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

*::selection {
  background: var(--color-primary-light);
  color: var(--color-text-in-primary);
}

html,
body,
:root {
  height: 100vh;
  width: 100vw;
}

button {
  cursor: pointer;
}

button,
textarea,
input,
select {
  font-size: 1rem;
  background: transparent;
  color: var(--black);
}

option {
  background-color: var(--white);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border-color);
  -webkit-text-fill-color: var(--black);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar-track {
  background: var(--white);
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-blue);
}

::selection {
  color: var(--gray-light);
  background: var(--secondary-blue);
}

.theme-light {
  --gray: #e8e8ea;
  --gray-light: #ddd;
  --white: #fff;
  --black: #222;
  --orange: #f5ab00;
  --red: #b40d01;
  --green: #01b578;
  --secondary-blue: #2e3192;
  --secondary-blue-light: #394494;
  --primary-blue: #c4dcf8;
  --primary-blue-light: #dfedfd;
  --yellow: #fff200;
  --border-color: #39449490;
}

.theme-light button {
  background: var(--secondary-blue);
  color: var(--white);
}

.theme-dark {
  --gray: #e8e8ea;
  --gray-light: #444;
  --white: #222;
  --black: #fff;
  --orange: #b78002;
  --red: #960e04;
  --green: #069565;
  --secondary-blue: #eee;
  --secondary-blue-light: #fff;
  --primary-blue: #3a4756;
  --primary-blue-light: #313c48;
  --yellow: #918c2e;
  --border-color: #77777750;
}

.theme-dark input::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.theme-dark button {
  background: var(--primary-blue);
  color: var(--black);
}

/* * {
  --background-form: #ddd;
  --background-app: #fff;
  --color-primary: #2e3192;
  --color-secundary: #fff200;
  --color-primary-light: #394494;
  --color-text-in-primary: #fff;
  --color-text-black: #222;
  --background-header: #c4dcf8;
  --background-calendar: #fff;
  --background-header-calendar: #dfedfd;
  --background-hover-gray: #ddd;
  --background-item-today: #ccc;
  --background-mark-wait: #f5ab00;
  --background-mark-not-showed-up: #b40d01;
  --background-mark-confirmed: #01b478;
  --color-text-in-mark: #fff;
  --color-border-input: #2e3192;
} */


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

*::selection {
  background: var(--color-primary-light);
  color: var(--color-text-in-primary);
}

html,
body,
:root {
  height: 100vh;
  width: 100vw;
}

button {
  cursor: pointer;
}

button,
textarea,
input,
select {
  font-size: 1rem;
  background: transparent;
  color: var(--black);
}

option {
  background-color: var(--white);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border-color);
  -webkit-text-fill-color: var(--black);
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar-track {
  background: var(--white);
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-blue);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--gray-light);
  background: var(--secondary-blue);
}

::selection {
  color: var(--gray-light);
  background: var(--secondary-blue);
}
